import LoginForm from '../LoginForm'
import ConsultaSocios from '../ConsultaSocios'
import auth from '../../auth'

const Content = () => {
	return (
		<>
			{ auth ? (<ConsultaSocios />) : (<LoginForm />) }
		</>
	)
}

export default Content