import './App.css';
import Content from './components/Content'
import Footer from './components/Footer'
import logo from './logo.svg';

function App() {
    return (
        <div className="app">
            <header className="header">
                <img src={logo} width="100" className="logo"/> 
            </header>
            <Content />
            <Footer />
        </div>
    );
}

export default App;
