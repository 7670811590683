const Footer = () => {
	return (
		<footer className="py-3">
			<p className="mb-0">CLUB SOCIAL Y DEPORTIVO COLOCOLO © 2022 - TODOS LOS DERECHOS RESERVADOS</p>
			<p className="mb-0">
				Marathon 5300, Macul, Región Metropolitana, Chile  </p>
			<p className="mb-0">
				E-mail:{' '}
				<a href="mailto:socios@csdcolocolo.cl">socios@csdcolocolo.cl</a>
			</p>
		</footer>
	)
}

export default Footer;