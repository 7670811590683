import { useState } from 'react';
import axios from 'axios';
const LoginForm = props => {
	const [loading, setLoading] = useState(false);
	const [inputs, setInputs] = useState({});
	const handleInput = (e) => {
		setInputs({
			...inputs,
			[e.target.name]: e.target.value,
		});
	};
	const handleIngresar = async () => {
		try {
			setLoading(true);
			const data = { ...inputs };
			const URL = process.env.REACT_APP_API_URL_LOGIN;
			const res = await axios.post(URL + '/users/login', data);
			if (res?.data.response) {
				localStorage.setItem('authorization', res?.data.token)
				window.location.reload(true);
			} else {
				console.info("Ocurrió un problema")
			}
		} catch (e) {
			console.log(e)
		}
		setLoading(false);
	};

	return (
		<div className='container'>
			<div className="row py-5 my-5 p-md-5 justify-content-center">
				<div className="col-md-6">
					<div className="form-inputs">
						<h3>Login</h3>
						<div className="mb-3">
							<input type="text" name="username" className="form-control" placeholder="Usuario" onChange={handleInput} value={inputs.username} />
						</div>
						<div className="mb-3">
							<input type="password" name="password" className="form-control" placeholder="Password" onChange={handleInput} value={inputs.password} />
						</div>
						<button type="submit" className="btn btn-primary mb-2" onClick={handleIngresar}>
							<span>Ingresar</span>
							{
								loading ? (
									<div className="spinner-border" role="status">
										<span className="sr-only">Loading...</span>
									</div>
								) : ('')
							}
						</button>
					</div>
				</div>
			</div>
		</div>
	)
}

export default LoginForm;