import { useState } from 'react';
import axios from 'axios';
import { formatRut, validateRut, unformatRut, encode, decode } from '../../Service/Utils';

const ConsultaSocios = () => {
	const [loading, setLoading] = useState(false);
	const [show, setShow] = useState(false);
	const [btnDisabled, setBtnDisabled] = useState(false)
	const [response, setResponse] = useState({});
	const [validate, setValidate] = useState([false, '']);
	const [inputs, setInputs] = useState({});
	const handleInput = (e) => {
		setInputs({
			...inputs,
			[e.target.name]: formatRut(e.target.value),
		});
	};
	let date = new Date();
	const _verifiqueToken = async () => {
		if (
			decode(localStorage.getItem('token')) === null &&
			decode(localStorage.getItem('dateExpired')) === null
		) {
			console.info("no token - no date");
			return await _getToken();
		}
		if (decode(localStorage.getItem('token')) === null) {
			console.info("no token - update");
			return await _getToken();
		} else if (Date.parse(date) > decode(localStorage.getItem('dateExpired'))) {
			console.info("token expired - update");
			return await _getToken();
		} else {
			console.info("token");
			return true;
		}
	};
	const _getToken = async () => {
		setLoading(true)
		setBtnDisabled(true)
		try {
			const URL = process.env.REACT_APP_API_URL_LOGIN;
			const res = await axios.post(URL + '/users/token/renew', { renew_token: localStorage.getItem('authorization') });
			if (res.status === 200) {
				setLoading(false)
				setBtnDisabled(false)
				localStorage.setItem('token', encode(res?.data.token))
				localStorage.setItem('dateExpired', encode(date.setMinutes(date.getMinutes() + 1400)))
				return true;
			}
		} catch (e) {
			setLoading(false)
			setBtnDisabled(false)
			console.log(e)
			return false;
		}
	};
	const handleConsultar = async () => {
		setShow(false);
		setValidate([false, '']);
		if (inputs.rut === "" || typeof inputs.rut === 'undefined') {
			setValidate([true, 'Debe ingresar el rut'])
			return false;
		}
		if (!validateRut(inputs.rut)) {
			setValidate([true, 'Rut no válido'])
			return false;
		}
		if (await _verifiqueToken() !== true) {
			setValidate([true, 'Token no generado, favor intentar nuevamente'])
			return false;
		}
		setBtnDisabled(true);
		setLoading(true);
		try {
			const params = { rut: unformatRut(inputs.rut).replace("-", "") };
			const URL = process.env.REACT_APP_API_URL_SOCIO;
			const res = await axios.post(URL, params, {
				headers: {
					'Authorization': `Bearer ${decode(localStorage.getItem('token'))}`,
				}
			});
			if (res.status === 200) {
				setShow(true);
				setResponse({ ...res?.data })
			}
		} catch (e) {
			console.log(e)
			setValidate([true, 'Problema interno, favor comunicarse con el administrador'])
		}
		setLoading(false);
		setBtnDisabled(false);
	};
	const handleLogout = (e) => {
		localStorage.clear();
		window.location.reload(true);
	}
	const Message = ({ nombre_socio, data, status }) => {
		return (
			<div className={`alert ${status ? 'alert-success' : 'alert-warning'}`} role="alert">
				<h4 className="alert-heading">Resultado Búsqueda: </h4>
				{nombre_socio === '' ? (
					<p>{data}</p>
				) : (
					<p>El socio <strong>{nombre_socio}</strong> se encuentra con el siguiente status: <strong>{data}</strong></p>
				)}
			</div>
		)
	}
	return (
		<div className='container'>
			<div className='row'>
				<div className='col-12 text-right'>
					<p className='my-2 logout' onClick={(e) => handleLogout(e)}>Cerrar Sessión</p>
				</div>
			</div>
			<div className="row py-5 my-5 p-md-5 justify-content-center">
				<div className="col-md-6">
					<h3 className='mb-3'>Consultar socios al día</h3>
					<div className="form-inputs">
						<label className="sr-only" htmlFor="rut">RUT</label>
						<input type="text" className="form-control mb-2 mr-sm-2" id="rut" name="rut" placeholder="12.345.678-0" onChange={handleInput} value={inputs.rut} />
						<button type="submit" disabled={btnDisabled} className="btn btn-primary mb-2" onClick={handleConsultar}>
							<span>Consultar</span>
							{
								loading ? (
									<div className="spinner-border mx-2" role="status">
										<span className="sr-only">Loading...</span>
									</div>
								) : ('')
							}
						</button>
					</div>
					{validate[0] && (
						<div className="alert alert-danger" role="alert">
							{validate[1]}
						</div>
					)}
					{show && (
						<Message {...response} />
					)}
				</div>
			</div>
		</div>
	)
}

export default ConsultaSocios;